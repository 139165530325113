import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/auth/models';
import { ApiRoute } from 'environments/apiroute.prod';
import { Endpoint } from 'environments/endpoint.prod';
import { GridQueryModel } from '../modals/common.model';
import { UserRoleModel } from './model/user-role.model';
import { UserModel } from './model/user.model';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private _httpClient: HttpClient) { }

  userMap = new Map<string, any>();
  attroneyUsers = [];
  usersArr = [];
  activeUsers = [];
  activeAttorneys = [];
  attorneysEmail = [];
  users: any  = null;
  caseUsers: any = null;
  name = '56499411346$%#!asd';

  getUsers(request: GridQueryModel): Promise<any[]> {
    return this._httpClient.post<any[]>(Endpoint.USER_MICROSERVICE + ApiRoute.GET_ALL, request).toPromise();
  }

  async getUserRoles(id: string): Promise<UserRoleModel> {
    return this._httpClient.get<UserRoleModel>(`${Endpoint.USER_MICROSERVICE + ApiRoute.GET_USER_ROLES + id}/Roles`).toPromise();
  }

  async updateUserRoles(request: UserRoleModel) {
    return this._httpClient.post<UserRoleModel>(`${Endpoint.USER_MICROSERVICE + ApiRoute.GET_USER_ROLES + request.uid}/Roles`, request).toPromise();
  }

  async createUser(request): Promise<any> {
    return this._httpClient.post<any>(Endpoint.USER_MICROSERVICE + ApiRoute.CREATE_USER, request).toPromise();
  }

  async updateUser(request): Promise<any> {
    return this._httpClient.post<any>(Endpoint.USER_MICROSERVICE + ApiRoute.UPDATE_USER, request).toPromise();
  }

  async handleLogin(request, headers): Promise<any> {
    return this._httpClient.post<any>(Endpoint.USER_MICROSERVICE + ApiRoute.HANDLE_LOGIN, request, headers).toPromise();
  }

  async createTeam(request): Promise<any> {
    return this._httpClient.post<any>(Endpoint.USER_MICROSERVICE + ApiRoute.CREATE_TEAM, request).toPromise();
  }

  async getTeams(): Promise<any> {
    return this._httpClient.get<any>(Endpoint.USER_MICROSERVICE + ApiRoute.GET_TEAMS).toPromise();
  }

  async checkUser(email,headers): Promise<any> {
    return this._httpClient.get<any>(Endpoint.USER_MICROSERVICE + ApiRoute.CHECK_USER_BY_EMAIL +'/'+email,headers).toPromise();
  }

  async passwordReset(request, headers): Promise<any> {
    return this._httpClient.post<any>(Endpoint.USER_MICROSERVICE + ApiRoute.PASSWORD_RESET, request, headers).toPromise();
  }

  async userPasswordReset(request, headers): Promise<any> {
    return this._httpClient.post<any>(Endpoint.USER_MICROSERVICE + ApiRoute.USER_PASSWORD_RESET, request, headers).toPromise();
  }

  async getActiveUsers(): Promise<UserModel[]> {
    let users = await this._httpClient.get<UserModel[]>(Endpoint.USER_MICROSERVICE + ApiRoute.GET_ALL_USERS).toPromise();
    return users.filter(u => (u.status == "true"));
  }

  async getUserDetailBasedOnUserId(ids, roleName): Promise<any> {
    if (!sessionStorage.getItem("users")) {
      this.users = await this.getActiveUsers();
      this.setUsersToSession(this.users, "users");
    } else {
      this.users = sessionStorage.getItem("users") ? this.getUsersFromSession("users") : [];
    }
    let filteredUsers = [];
    let userMap = new Map<string, string>();

    if (roleName) {
      this.users.filter(u => (u.role == roleName))?.map(ele => {
        filteredUsers.push(ele);
      });
    } else if (ids && ids.length > 0) {
      for (let id of ids) {
        this.users.filter(u => (u.userId == id))?.map(ele => {
          filteredUsers.push(ele);
        });
      }
    } else {
      this.users.filter(u => (u))?.map(ele => {
        userMap.set(ele.userId, ele.firstName + ' ' + ele.lastName);
      });
      return userMap;
    }
    return filteredUsers;
  }

  setUsersToSession(value, type) {
    var user =  CryptoJS.AES.encrypt(JSON.stringify(value), this.name).toString();
    sessionStorage.setItem(type, user);
  }

  getUsersFromSession(type) {
    const bytes = CryptoJS.AES.decrypt(sessionStorage.getItem(type), this.name);
    var user =  bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(user);
  }

  async getCaseUsers(): Promise<UserModel[]> {
    return this._httpClient.get<any>(Endpoint.USER_MICROSERVICE + ApiRoute.GET_USERS).toPromise();

  }

  async getUsersBasedOnId(type, active?): Promise<any> {
      if (!sessionStorage.getItem("caseUsers")) {
        this.caseUsers = await this.getCaseUsers();
        this.setUsersToSession(this.caseUsers, "caseUsers");
      } else {
        this.caseUsers = this.getUsersFromSession("caseUsers");
      }
     this.usersArr = this.caseUsers.filter(u => (u))?.map(ele => {
        this.userMap.set(ele.userId, ele);
        return {
          code: ele.userId,
          description: ele.firstName + ' ' + ele.lastName + ' - ' + ele.emailAddress
        }
      });

      this.activeUsers = this.caseUsers.filter(u => (u.status == 'true'))?.map(ele => {
        this.userMap.set(ele.userId, ele);
        return {
          code: ele.userId,
          description: ele.firstName + ' ' + ele.lastName  + ' - ' + ele.emailAddress
        }
      });
      this.attroneyUsers = this.caseUsers.filter(u => u.role === 'Attorney' || u.role == 'Admin')?.map(ele => {
        return {
          code: ele.userId,
          description: ele.firstName + ' ' + ele.lastName  + ' - ' + ele.emailAddress
        }
      })

      this.activeAttorneys = this.caseUsers.filter(u => (u.role === 'Attorney' || u.role == 'Admin') && u.status =='true' )?.map(ele => {
        return {
          code: ele.userId,
          description: ele.firstName + ' ' + ele.lastName  + ' - ' + ele.emailAddress
        }
      })
 
      this.attorneysEmail = this.caseUsers.filter(u => (u.role === 'Attorney' || u.role == 'Admin') && u.status =='true' )?.map(ele => {
        return  (ele.firstName + ' ' + ele.lastName  + ' - ' + ele.emailAddress)
        
      })


    
    if( active == 'active'){
      if (type == 'Attorney') {
        return this.activeAttorneys
      } else if(type == 'users'){
        return this.activeUsers
      } 
    } else {
      if (type == 'Attorney') {
        return this.attroneyUsers
      } else if(type == 'users'){
        return this.usersArr
      } else if(type == 'email'){
        return this.attorneysEmail
      } else {
        return this.userMap;
      }
    }
   
  }

  async getLawtraxUserByMail(request): Promise<any> {
    return this._httpClient.post<any>(Endpoint.USER_MICROSERVICE + ApiRoute.GET_USER_BY_EMAIL, request).toPromise();
  }

  async sendNotificationEmail(request: any): Promise<any> {
    return this._httpClient.post(Endpoint.USER_MICROSERVICE + ApiRoute.SEND_NOTIFICATION_MAIL, request).toPromise();
  }
}