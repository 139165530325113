import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRoute } from 'environments/apiroute.prod';
import { Endpoint } from 'environments/endpoint.prod';
import { BatchModel, JobDetailsModel, RecruitemntModel } from './model/add-recruitment.dto';

@Injectable()
export class CustomerManagementService {
public primaryRoute: string = Endpoint.CUSTOMER_MICROSERVICE + ApiRoute.CUSTOMER;

public rec_primaryRoute: string = Endpoint.CUSTOMER_MICROSERVICE + ApiRoute.PERM;

/**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
 constructor(private _httpClient: HttpClient) {
}

  getDataTableRows(): Promise<any> {
    return this._httpClient.get(this.primaryRoute).toPromise();
  }
  getGridTableRows(obj): Promise<any> {
    return this._httpClient.post(this.primaryRoute + ApiRoute.GET_ALL_CUSTOMERS, obj).toPromise();
  }

  getCutomer(id: string): Promise<any> {
    return this._httpClient.get(this.primaryRoute + id).toPromise();
  }

  getCustomerBasicDetails(): Promise<any> {
    return this._httpClient.get(this.primaryRoute + ApiRoute.GET_CUSTOMER_BASIC).toPromise();
  }

  createCustomer(customer: any): Promise<any> {
    return this._httpClient.post(this.primaryRoute, customer).toPromise();
  }

  createContact(customerId: string, contact: any): Promise<any> {
    return this._httpClient.post(`${this.primaryRoute}${customerId + ApiRoute.CONTACT}`, contact).toPromise();
  }

  createDocument(customerId: string, document: any): Promise<any> {
    return this._httpClient.post(this.primaryRoute + customerId + ApiRoute.DOCUMENT, document).toPromise();
  }

  updateCustomer(request: any): Promise<any> {
    return this._httpClient.put(this.primaryRoute + request.customer.customer_id, request).toPromise();
  }

  updateContact(customerId: string, contact: any): Promise<any> {
    return this._httpClient.put(`${this.primaryRoute}${customerId + ApiRoute.CONTACT}`, contact).toPromise();
  }

  updateDocument(customerId: string, document: any): Promise<any> {
    return this._httpClient.put(`${this.primaryRoute}${customerId + ApiRoute.DOCUMENT}/${document.document.documentId}`, document).toPromise();
  }

  deleteDocument(customerId: string, documentId: any): Promise<any> {
    return this._httpClient.delete(`${this.primaryRoute}${customerId + ApiRoute.DOCUMENT}/${documentId}`).toPromise();
  }

  getCustomerDocument(request: any): Promise<any> {
    return this._httpClient.post(`${this.primaryRoute}${ApiRoute.GET_CUSTOMER_DOCUMENT}`, request).toPromise();
  }


  createPetitioner(customerId: string, petitioner: any): Promise<any> {
    return this._httpClient.post(`${this.primaryRoute}${customerId + ApiRoute.PETITION}`, petitioner).toPromise();
  }

  deleteCustomer(customerId: any): Promise<any> {
    return this._httpClient.delete(`${this.primaryRoute}${customerId + ApiRoute.CUSTOMER}`).toPromise();
  }


  getCustomerBasicDetailsById(id: string): Promise<any> {
    return this._httpClient.get(this.primaryRoute + ApiRoute.GET_CUSTOMER_BASIC_BY_ID+id).toPromise();
  }
  getCustomerByEmail(email: string): Promise<any> {
    return this._httpClient.get<any>(this.primaryRoute + ApiRoute.GET_CUSTOMER_EMAIL + email).toPromise();
  }

  async ackAlert(request: any, id: string): Promise<any> {
    return this._httpClient.put(`${this.primaryRoute + ApiRoute.CUSTOMER_UPDATE_ALERT}/${id}`, request).toPromise();
  }

  getBeneficiariesBasicByEmail(email: string): Promise<any> {
    return this._httpClient.get<any>(this.primaryRoute + ApiRoute.GET_CUST_BENE_EMAIL + email).toPromise();
  }

  createRecruitment(customer: RecruitemntModel): Promise<any> {
    return this._httpClient.post(this.rec_primaryRoute + ApiRoute.CREATE_PERM, customer).toPromise();

  }

  
  createBatch(batch: BatchModel): Promise<any> {
    return this._httpClient.post(this.rec_primaryRoute + ApiRoute.CREATE_BATCH, batch).toPromise();

  }

  createJob(job: JobDetailsModel): Promise<any> {
    return this._httpClient.post(this.rec_primaryRoute + ApiRoute.CREATE_JOB, job).toPromise();

  }
  
  createJobDocument(job): Promise<any> {
    return this._httpClient.post(this.rec_primaryRoute + ApiRoute.DOCUMENT, job).toPromise();

  }
  getRecruitGridTableRows(obj): Promise<any> {
    return this._httpClient.post(this.rec_primaryRoute + ApiRoute.GET_ALL_PERM_CUSTOMERS, obj).toPromise();
  }
  deleteCompanyById(id) :Promise<any> {

    return this._httpClient.delete<any>(this.rec_primaryRoute + ApiRoute.DELETE_COMPANY + id).toPromise();
  }
  getCustomerBatches(customerId) :Promise<any> {

    return this._httpClient.get<any>(this.rec_primaryRoute + ApiRoute.GET_ALL_BATCHES_BY_CUSTOMER + customerId).toPromise();
  }


  getBatcheJobs(batchId) :Promise<any> {

    return this._httpClient.get<any>(this.rec_primaryRoute + ApiRoute.GET_ALL_JOBS_BY_BATCH + batchId).toPromise();
  }
  getBatchById(id) :Promise<any> {

    return this._httpClient.get<any>(this.rec_primaryRoute + ApiRoute.GET_BATCH_BY_ID + id).toPromise();
  }
  deleteBatchById(id) :Promise<any> {

    return this._httpClient.delete<any>(this.rec_primaryRoute + ApiRoute.DELETE_BATCH_BY_ID + id).toPromise();
  }
  getJobById(jobId) :Promise<any> {

    return this._httpClient.get<any>(this.rec_primaryRoute + ApiRoute.GET_JOB_BY_ID + jobId).toPromise();
  }

  getJobCases(jobId) :Promise<any> {

    return this._httpClient.get<any>(this.rec_primaryRoute + ApiRoute.JOB_CASES_BY_ID + jobId).toPromise();
  }

  deleteJobById(jobId) :Promise<any> {

    return this._httpClient.delete<any>(this.rec_primaryRoute + ApiRoute.DELETE_JOB_BY_ID + jobId).toPromise();
  }
  getJobDocuments(jobId) :Promise<any> {

    return this._httpClient.get<any>(this.rec_primaryRoute + ApiRoute.GET_JOB_DOCUMENTS + jobId).toPromise();
  }

  updateJobDocument(docId: string, document: any): Promise<any> {
    return this._httpClient.put<any>(this.rec_primaryRoute + ApiRoute.UPDATE_JOB_DOCUMENT + docId, document).toPromise();
  }

  deleteJobDocument(docId: string): Promise<any> {
    return this._httpClient.delete<any>(this.rec_primaryRoute + ApiRoute.DELETE_JOB_DOCUMENT + docId).toPromise();
  }

  getJobsByBatch(id: string,op: string): Promise<any> {
    return this._httpClient.get(this.primaryRoute + ApiRoute.GET_JOB_BY_CUSTOMERID + id + '?op='+op ).toPromise();
  }

  async executeReportQuery(request): Promise<any> {
    return this._httpClient.post<any>(this.primaryRoute + ApiRoute.EXECUTE_REPORT_QUERY, request).toPromise();
  }

  async uploadCustomer(request : any): Promise<any> {
    return this._httpClient.post(`${this.primaryRoute + ApiRoute.CUSTOMER_UPLOAD }`, request).toPromise();
  }

}
